
import Error from "@/components/Error.vue";
import PrettyBar from "@/components/PrettyBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import useError from "@/composables/useError";
import { RoleType, useGetUserQuery, UserType } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { computed, defineComponent, reactive, ref, watch, toRef } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useConfirmService from "@/composables/useConfirmService";
import useToastService from "@/composables/useToastService";
import useRandomPassword from "@/composables/useRandomPassword";
import usePasswordValidation from "@/composables/usePasswordValidation";
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import useDeleteUser from "./mutations/useDeleteUser";
import useCreateUser from "./mutations/useCreateUser";
import useUpdateUser from "./mutations/useUpdateUser";
import RolePicker from "./components/RolePicker.vue";
import useAddUserRole from "./mutations/useAddUserRole";
import useRemoveUserRole from "./mutations/useRemoveUserRole";
import VDButton from "@/components/VDButton.vue";

// Queries
gql`
  query getUser($id: ID) {
    user(userId: $id) {
      ...UserParts
      roles {
        roleId
        name
        application {
          name
        }
      }
    }
  }
`;

export default defineComponent({
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    PrettyBar,
    Sidebar,
    Error,
    RolePicker,
    VDButton,
  },
  setup(props) {
    // Services
    const confirmService = useConfirmService("sidebar");
    const router = useRouter();
    const toastService = useToastService();

    // VDOM Refs
    const rolePicker = ref<VDPopupListboxClass>();

    // Properties
    const originalUser = ref<UserType>({});
    const user = reactive<UserType>({ roles: [] });
    const hasChanges = computed(
      () =>
        !(
          originalUser.value.firstname == user.firstname &&
          originalUser.value.lastname == user.lastname &&
          originalUser.value.phone == user.phone &&
          originalUser.value.email == user.email &&
          originalUser.value.administrator == user.administrator
        )
    );
    const password = ref<string>(useRandomPassword());
    const isNewUser = computed(() => props.id == "new");

    const v$ = useVuelidate(useValidations(), {
      email: toRef(user, "email"),
      firstname: toRef(user, "firstname"),
      lastname: toRef(user, "lastname"),
      password: password,
    });
    const hasConfirmedLeave = ref(false);

    const { result, loading, error } = useGetUserQuery(props, () => ({
      enabled: props.id != "new",
      fetchPolicy: "network-only",
    }));

    const deleteUserMutation = useDeleteUser(props.organizationId, props.id);
    const createUserMutation = useCreateUser(
      props.organizationId,
      user,
      password
    );

    const updateUserMutation = useUpdateUser(
      props.organizationId,
      props.id,
      user
    );
    const addUserRole = useAddUserRole();
    const removeUserRole = useRemoveUserRole();

    // Functions
    function onAddedRole(role: RoleType) {
      function add() {
        user.roles = [...(user.roles ?? []), role];
      }

      if (!isNewUser.value) {
        addUserRole.call(role.roleId ?? "", props.id).then((value) => {
          if (value != null) add();
        });
      } else {
        add();
      }
    }

    function onRemovedRole(role: RoleType) {
      function remove() {
        user.roles =
          user.roles?.filter((element) => element?.roleId != role.roleId) ?? [];
      }

      if (!isNewUser.value) {
        removeUserRole.call(role.roleId ?? "", props.id).then((value) => {
          if (value != null) remove();
        });
      } else {
        remove();
      }
    }

    function primaryButtonPressed() {
      v$.value.$touch();
      if (!v$.value.$error)
        isNewUser.value ? createUserMutation.call() : updateUserMutation.call();
    }

    function copyPasswordToClipboard() {
      if (password.value) navigator.clipboard.writeText(password.value);
    }

    createUserMutation.onDone(({ data }) => {
      toastService.show({
        severity: "success",
        title: "Bruger oprettet",
        message: `${user.email} er blevet oprettet, og har fået ID ${data?.createUser?.userId}`,
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    createUserMutation.onError((error) => {
      toastService.show({
        severity: "error",
        error: useError(error),
      });
    });

    updateUserMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Bruger opdateret",
        message: `${user.email} er blevet opdateret`,
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    updateUserMutation.onError((error) => {
      toastService.show({
        severity: "error",
        error: useError(error),
      });
    });

    deleteUserMutation.onDone(({ errors }) => {
      errors;
      toastService.show({
        severity: "success",
        title: "Bruger slettet",
        message: `${user.email} med ID ${props.id} er blevet slettet!
          `,
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    deleteUserMutation.onError((error) => {
      toastService.show({
        severity: "error",
        error: useError(error),
      });
    });

    watch(
      () => useResult(result).value,
      (newValue) => {
        originalUser.value = newValue ?? {};

        Object.assign(user, newValue);
        user.roles = [...(newValue?.roles ?? [])];
      },
      { immediate: true }
    );

    onBeforeRouteLeave(() => {
      if (!hasConfirmedLeave.value && hasChanges.value && !isNewUser.value) {
        return new Promise((resolve) =>
          confirmService.show(
            null,
            "Du har foretaget ændringer der ikke er gemt, vil du stadig gå tilbage?",
            "Ja, gå tilbage",
            "Afbryd",
            () => {
              hasConfirmedLeave.value = true;
              resolve(true);
            },
            () => resolve(false)
          )
        );
      } else {
        return true;
      }
    });

    return {
      rolePicker,
      user,
      password,
      v$,
      error: useError(error),
      loading,
      isNewUser,
      hasChanges,
      createUserMutation,
      updateUserMutation,
      deleteUserMutation,
      onAddedRole,
      onRemovedRole,
      addRemoveLoading: computed(
        () => addUserRole.loading.value || removeUserRole.loading.value
      ),
      copyPasswordToClipboard,
      primaryButtonPressed,
      deleteUser: (event: Event) => {
        confirmService.show(
          event,
          "Er du sikker på at du vil slette brugeren? Denne handling kan ikke fortrydes",
          "Ja, slet",
          "Afbryd",
          () => deleteUserMutation.call()
        );
      },
    };
  },
});

function useValidations() {
  return {
    email: { email, required },
    firstname: { required },
    lastname: { required },
    password: usePasswordValidation(),
  };
}
